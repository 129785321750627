export const imgTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp']

// CommunityMessageで選択可能なファイルのMIMEタイプ
export const messageAttachedFileFormats = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
]

// CommunityMessageで選択可能なファイルの拡張子
// MIMEタイプのチェックだけでは、.dot, .xlt, .pot, .pps, .csvを選択できるため
export const messageAttachedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt']
